import TransparentBackground from '../TransparentBackground'
import SideMenuContent from './content/SideMenuContent'
import { motion } from 'framer-motion'

export default function SideMenuContainer({ showSideMenu, setShowSideMenu, children }) {
  const hideSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }

  const Sidebar = () => (
    <div
      className={`z-10 h-full bg-gray-200 py-10 px-6 text-white duration-200 ease-in-out dark:bg-gray-900`}
    >
      <SideMenuContent setShowSideMenu={setShowSideMenu} />
    </div>
  )

  const AnimatedSidebar = (
    <motion.div
      className="fixed top-0 left-0 h-full w-[300px]"
      animate={{ translateX: showSideMenu ? 0 : -300, transition: { delay: 0, duration: 0.35 } }}
      style={{
        translateX: -300,
      }}
    >
      <div className="flex h-full flex-col items-center justify-center">
        <Sidebar />
      </div>
    </motion.div>
  )

  return (
    <>
      <main className="h-full">{children}</main>
      <TransparentBackground onClick={hideSideMenu} show={showSideMenu} />
      {AnimatedSidebar}
    </>
  )
}
