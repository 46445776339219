import { useEffect } from 'react'
import pluginConfig from './CookieConsentConfig'
import 'vanilla-cookieconsent'

const CookieConsent = () => {
  useEffect(() => {
    document.documentElement.classList.add('c_darkmode')
    if (document.getElementById('cc--main')) {
      document.getElementById('cc--main').classList.add('theme_turquoise')
    }

    /**
     * useEffect is executed twice (React 18+),
     * make sure the plugin is initialized and executed once
     */
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent()
      window.CookieConsentApi.run(pluginConfig)
    }
  }, [])

  return null
}

export default CookieConsent
