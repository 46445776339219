import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

const TransparentBackground = ({ onClick, show }) => {
  const BlackContainer = styled(motion.div)`
    background-color: black;
    height: 100vh;
    width: 100vw;
  `

  return (
    <>
      <div
        onClick={onClick}
        className={`fixed right-0 bottom-0 z-0 ${show ? '' : 'pointer-events-none'}`}
      >
        <AnimatePresence>
          {show && (
            <BlackContainer
              initial={{ opacity: 0 }}
              animate={{
                opacity: 0.5,
                transition: { delay: 0, duration: 0.3 },
              }}
              exit={{
                opacity: 0,
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

export default TransparentBackground
