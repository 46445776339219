import Link from 'next/link'

export default function SideMenuItem({ selected, text, page, setShowSideMenu, setSelectedPage }) {
  return (
    <Link
      href={page}
      passHref
      className={`${
        selected ? 'bg-gray-300 dark:bg-gray-800' : ''
      } h-10 rounded-lg pt-1 pl-2 text-left duration-100 ease-in-out hover:bg-gray-300 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-white`}
      onClick={() => {
        setShowSideMenu(false)
        setSelectedPage(page)
      }}
    >
      <li>
        <button className={`${selected ? 'font-semibold' : ''} prose dark:prose-dark`}>
          {text}
        </button>
      </li>
    </Link>
  )
}
