import ScalingComponent from '../ScalingComponent'

const SideMenuButton = ({ onClick }) => {
  return (
    <ScalingComponent
      content={
        <button onClick={onClick}>
          <svg
            className="active:stroke-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            stroke="currentColor"
          >
            <path d="M3.625 17.175v-.925H20.4v.925Zm0-4.725v-.925H20.4v.925Zm0-4.7v-.925H20.4v.925Z" />
          </svg>
        </button>
      }
    />
  )
}

export default SideMenuButton
