import ScalingComponent from '../ScalingComponent'

const StatsButton = ({ onClick }) => {
  return (
    <ScalingComponent
      content={
        <button
          aria-label="Show stats"
          type="button"
          className="mx-1 h-8 w-8 rounded p-1 sm:ml-4"
          onClick={onClick}
        >
          <svg
            className="active:stroke-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              fill="currentColor"
              d="M16,11V3H8v6H2v12h20V11H16z M10,5h4v14h-4V5z M4,11h4v8H4V11z M20,19h-4v-6h4V19z"
            ></path>
          </svg>
        </button>
      }
    />
  )
}

export default StatsButton
