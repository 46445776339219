import SideMenuButton from '../buttons/SideMenuButton'
import siteMetadata from '@/data/siteMetadata'
import Link from 'next/link'

export default function HeaderLeftSection({ headerStateManager }) {
  return (
    <div className="flex flex-row items-center space-x-4 leading-3">
      <SideMenuButton
        onClick={() => {
          headerStateManager.setShowSideMenu(true)
        }}
      />
      <button>
        <h1 className="text-2xl font-semibold">
          <Link href="/">{siteMetadata.headerTitle}</Link>
        </h1>
      </button>
    </div>
  )
}
