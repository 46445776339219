import ThemeSwitch from '../ThemeSwitch'
import StatsButton from '../buttons/StatsButton'
import HowToPlayButton from '../buttons/HowToPlayButton'
import { isGamePage } from 'utils/pageRoutes'
import { useRouter } from 'next/router'

export default function HeaderRightSection({ headerStateManager }) {
  const router = useRouter()

  return (
    <div>
      <div className="flex flex-row items-center leading-5">
        {isGamePage(router.pathname) && (
          <StatsButton
            onClick={() => {
              headerStateManager.setShowStatsModal(true)
            }}
          />
        )}
        {isGamePage(router.pathname) && (
          <HowToPlayButton
            onClick={() => {
              headerStateManager.setShowHowToPlayModal(true)
            }}
          />
        )}
      </div>
    </div>
  )
}
