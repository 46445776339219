import HeaderLeftSection from './HeaderLeftSection'
import HeaderRightSection from './HeaderRightSection'
import { motion } from 'framer-motion'

export default function Header({ headerStateManager }) {
  return (
    <header>
      <div className="min-w-screen h-16 border-b border-gray-300 dark:border-gray-600">
        <div className="flex h-full w-full flex-row items-center justify-between px-4 xl:px-16">
          <HeaderLeftSection headerStateManager={headerStateManager} />
          <HeaderRightSection headerStateManager={headerStateManager} />
        </div>
      </div>
    </header>
  )
}
